import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next"
import { useLocation,useNavigate} from "react-router-dom";
import { generateYearsArray } from '@/utils/index';

export default function MobileNavigationBar() {
  let navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [locale, setLocale] = useState(i18n.language)
  const [pathName, setPathName] = useState(useLocation().pathname)
  const currentPath = useLocation().pathname + useLocation().hash
  const [clickItem, setClickItem] = useState(null)
  const [reviewItemsArr, setReviewItemsArr] = useState([])
  const [isShowMenu, setIsShowMenu] = useState(false)

  useEffect(() => {
    const yearArr = generateYearsArray(2023)
    setReviewItemsArr(yearArr)
    console.log(currentPath)
  }, [])

  useEffect(() => {
    // setPathName(useLocation().pathname)
    setIsShowMenu(false)
  }, [useLocation()])

  // const showNavMenu = () => {
  //   setIsShowMenu(!isShowMenu)
  // }
  const clickNav = (item) => {
    if (clickItem === item) {
      setClickItem(null)
    } else {
      setClickItem(item)
    }
  }

  const onSelectChange = (lang) => {
    i18n.changeLanguage(lang)
    localStorage.setItem('lang', lang)
    localStorage.setItem('lang', lang)
    if (process.env.NODE_ENV === 'development') {
      window.location.reload()
      // navigate('/');
      // window.location.reload()
    } else {
      navigate('/');
      window.location.reload()
    }
    // if (locale !== lang) {
    //   Cookies.set('lang',lang)
    //   router.replace('/')
    //   window.location.reload()
    // }
  }
  return (
    <div className={` fixed left-0 top-0 right-0 z-[9999999] ${isShowMenu ? "h-screen w-screen fixed left-0 top-0 z-[999] bg-white" : "w-full h-auto "}`}>
      <div className="h-[70px] pl-[5px] pr-[15px] flex items-center justify-between bg-[#174193]">
        <Link to="/">
          <img className="w-[120px] h-auto" src={require("@/imgs/navigation/logo.png")} alt="North Bound Forum" />
        </Link>
        <div className="flex items-center">
          <img onClick={() => setIsShowMenu(!isShowMenu)} className="w-6 h-6 mr-6" src={isShowMenu ? require("@/imgs/navigation/mobole_close.png") : require("@/imgs/icons/mobileNav_more.png")} alt="" />
          <div className="text-[11px] flex items-center justify-between px-2 py-1 border-[1px] border-white rounded-[14px] font-bolder">
            <span onClick={() => onSelectChange('zh')} className={`${locale === 'zh' ? 'text-white' : 'text-[#26ADDF]'}`}>中</span>
            <span className="px-2 font-bold text-[#26ADDF]">|</span>
            <span onClick={() => onSelectChange('en')} className={`${locale === 'en' ? 'text-white' : 'text-[#26ADDF]'}`}>EN</span>
          </div>
        </div>
      </div>

      <div className={`bg-white fixed left-0 top-[70px] overflow-hidden p-9 z-50 text-base text-[#004190] font-bold duration-300 ${isShowMenu ? "  w-screen" : "w-0 ml-[-50%]"}`}>
        <div className="mb-8">
          <Link to="/">
            <span className={`mr-8 2xl:mr-10 pr-[10px] relative font-bold after:absolute ${currentPath === '/' ? " text-[#26ADDF]" : ""}`}>{t('nav.home')}</span>
          </Link>
        </div>
        <div className="mb-8">
          <Link className="" to={`/focus-topic`}>
            <span className={`mr-8 2xl:mr-10 pr-[10px] relative font-bold  ${currentPath === '/focus-topic' ? " text-[#26ADDF]" : ''}`}>{t('nav.focus')}</span>
          </Link>
        </div>
        <div className="mb-8">
          <span className={`mr-8 2xl:mr-10 pr-[10px] relative after:absolute after:top-[10px] after:right-[-4px] after:content-[''] after:w-[8px] after:h-[6px] after:bg-cover after:bg-center ${currentPath === '/forum' ? "after:bg-[url('@/imgs/navigation/pulldown.png')] after:rotate-180 text-[#26ADDF]" : "after:bg-[url('@/imgs/navigation/pulldown_deepBlue.png')]"}`} onClick={() => clickNav('forum')}>{t('nav.currentForum')}</span>
          <div className={`text-[14px] overflow-hidden duration-300 ${clickItem === 'forum' ? "h-auto" : "h-0"}`}>
            <Link className={`pl-[14px] pt-6 py-3 block ${currentPath === "/forum" ? "text-[#26ADDF]" : ""}`} to={`/forum`}>
              {t('nav.currentForum_item1')}
            </Link>
            {/* 暂时隐藏嘉宾类型 */}
            {/* <Link className={`pl-[14px] pt-6 py-3 block ${currentPath === "/forum#speakers" ? "text-[#26ADDF]" : ""}`} to={`/forum#speakers`}>
              {t('nav.currentForum_item2')}
            </Link> */}
          </div>
        </div>
        <div className="mb-8">
          <span className={`2xl:mr-10 pr-[10px] relative after:absolute after:top-[10px] after:right-[-4px] after:content-[''] after:w-[8px] after:h-[6px] after:bg-cover after:bg-center ${currentPath.indexOf('/news-center') > -1 ? "after:bg-[url('@/imgs/navigation/pulldown.png')] text-[#26ADDF]" : "after:bg-[url('@/imgs/navigation/pulldown_deepBlue.png')]"}`} onClick={() => clickNav('news-center')}>{t('nav.newsCenter')}</span>
          <div className={`text-[14px] overflow-hidden duration-300 ${clickItem === 'news-center' ? "h-auto" : "h-0"}`}>
            <Link className={`pl-[14px] pt-6 py-3 block ${currentPath === "/news-center" ? "text-[#26ADDF]" : ""}`} to={`/news-center`}>
              {t('nav.newsCenter_item1')}
            </Link>
            <Link className={`pl-[14px] pt-6 py-3 block ${currentPath === "/news-center#industryNews" ? "text-[#26ADDF]" : ""}`} to={`/news-center#industryNews`}>
              {t('nav.newsCenter_item2')}
            </Link>
            <Link className={`pl-[14px] pt-6 py-3 block ${currentPath === "/news-center#informationRelease" ? "text-[#26ADDF]" : ""}`} to={`/news-center#informationRelease`}>
              {t('nav.newsCenter_item3')}
            </Link>
            <Link className={`pl-[14px] pt-6 py-3 block ${currentPath === "/news-center#typicalCases" ? "text-[#26ADDF]" : ""}`} to={`/news-center#typicalCases`}>
              {t('nav.newsCenter_item4')}
            </Link>
          </div>
        </div>
        <div className="mb-8">
          <span className={`2xl:mr-10 pr-[10px] relative after:absolute after:top-[10px] after:right-[-4px] after:content-[''] after:w-[8px] after:h-[6px] after:bg-cover after:bg-center ${currentPath.indexOf('/partners') > -1 ? "after:bg-[url('@/imgs/navigation/pulldown.png')] text-[#26ADDF]" : "after:bg-[url('@/imgs/navigation/pulldown_deepBlue.png')]"}`} onClick={() => clickNav('partners')} >{t('nav.partner')}</span>
          <div className={`text-[14px] overflow-hidden duration-300 ${clickItem === 'partners' ? "h-auto" : "h-0"}`}>
            <Link className={`pl-[14px] pt-6 py-3 block ${currentPath === "/partners" ? "text-[#26ADDF]" : ""}`} to={`/partners`}>
              {t('nav.partner_item1')}
            </Link>
            <Link className={`pl-[14px] pt-6 py-3 block ${currentPath === "/partners#cooperativePartners" ? "text-[#26ADDF]" : ""}`} to={`/partners#cooperativePartners`}>
              {t('nav.partner_item2')}
            </Link>
            <Link className="bg-white pl-[14px] py-3 block duration-300" to={`/partners#mediaPartners`}>
              {t('nav.partner_item3')}
            </Link>
            <Link className="bg-white pl-[14px] py-3 pb-0 block  duration-300" to={`/partners#specialAcknowledgement`}>
              {t('nav.partner_item4')}
            </Link>
          </div>
        </div>
        <div className="mb-8">
          <span className={`2xl:mr-10 pr-[10px] relative after:absolute after:top-[10px] after:right-[-4px] after:content-[''] after:w-[8px] after:h-[6px] after:bg-cover after:bg-center ${currentPath.indexOf('/live-center') > -1 ? "after:bg-[url('@/imgs/navigation/pulldown.png')] text-[#26ADDF]" : "after:bg-[url('@/imgs/navigation/pulldown_deepBlue.png')]"}`} onClick={() => clickNav('live-center')}>{t('nav.live')}</span>
          <div className={`text-[14px] overflow-hidden duration-300 ${clickItem === 'live-center' ? "h-auto" : "h-0"}`}>
            <Link className={`pl-[14px] pt-6 py-3 block ${currentPath === "/live-center" ? "text-[#26ADDF]" : ""}`} to={`/live-center`}>
              {t('nav.live_item1')}
            </Link>
            <Link className={`pl-[14px] py-3 pb-0 block ${currentPath === "/live-center#livePhotos" ? "text-[#26ADDF]" : ""}`} to={`/live-center#livePhotos`}>
              {t('nav.live_item2')}
            </Link>
          </div>
        </div>
        <div className="mb-8">
          <span className={`2xl:mr-10 pr-[10px] relative after:absolute after:top-[10px] after:right-[-4px] after:content-[''] after:w-[8px] after:h-[6px] after:bg-cover after:bg-center ${currentPath.indexOf('/review') > -1 ? "after:bg-[url('@/imgs/navigation/pulldown.png')] text-[#26ADDF]" : "after:bg-[url('@/imgs/navigation/pulldown_deepBlue.png')]"}`} onClick={() => clickNav('review')}>{t('nav.review')}</span>
          <div className={`text-[14px] overflow-hidden duration-300 ${clickItem === 'review' ? "h-auto" : "h-0"}`}>
            {
              reviewItemsArr.map((item, index) => {
                return (
                  <Link key={index} className={`pl-[14px] pt-6 py-3 block ${currentPath === "/review/" + item ? "text-[#26ADDF]" : ""} ${index === reviewItemsArr.length - 1 ? "pb-0" : ''}`} to={`${"/review/" + item}`}>
                    {t(`nav.review_${item}`)}
                  </Link>
                )
              })
            }
          </div>
        </div>
        <div className="mb-8">
          <span className={`2xl:mr-10 pr-[10px] relative after:absolute after:top-[10px] after:right-[-4px] after:content-[''] after:w-[8px] after:h-[6px] after:bg-cover after:bg-center ${currentPath.indexOf('/about-us') > -1 ? "after:bg-[url('@/imgs/navigation/pulldown.png')] text-[#26ADDF]" : "after:bg-[url('@/imgs/navigation/pulldown_deepBlue.png')]"}`} onClick={() => clickNav('about-us')}>{t('nav.aboutUs')}</span>
          <div className={`text-[14px] overflow-hidden duration-300 ${clickItem === 'about-us' ? "h-auto" : "h-0"}`}>
            <Link className={`pl-[14px] pt-6 py-3 block ${currentPath === "/about-us" ? "text-[#26ADDF]" : ""}`} to={`/about-us`}>
              {t('nav.aboutUs_item1')}
            </Link>
            <Link className={`pl-[14px] pt-6 py-3 block ${currentPath === "/about-us#organizationalStructure" ? "text-[#26ADDF]" : ""}`} to={`/about-us#organizationalStructure`}>
              {t('nav.aboutUs_item2')}
            </Link>
            <Link className={`pl-[14px] pt-6 py-3 block ${currentPath === "/about-us#consultant" ? "text-[#26ADDF]" : ""}`} to={`/about-us#consultant`}>
              {t('nav.aboutUs_item3')}
            </Link>
            <Link className={`pl-[14px] pt-6 py-3 block ${currentPath === "/about-us#advisoryCommittee" ? "text-[#26ADDF]" : ""}`} to={`/about-us#advisoryCommittee`}>
              {t('nav.aboutUs_item4')}
            </Link>
          </div>
        </div>
        <div>
          {/* <Link to="/edm"> */}
            <span className={`font-bold `}>
              <a href="https://registration.northbundforum.com/#/register?snowId=433222649304506">
                <span>{t('nav.register')}</span>
              </a>
              <span className="mx-2">|</span>
              <Link to="/edm">
                <span className={`${currentPath === '/edm' ? " text-[#26ADDF]" : ""}`}>{t('nav.subscribs')}</span>
              </Link>
              
            </span>
            
          {/* </Link> */}
        </div>
      </div>
    </div>
  )
}